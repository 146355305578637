//FME PAGE
.exhibmanV2--FME {
    $product-header-colour: #ed6839;
    .product-header {
        h2 a {
            color: $product-header-colour;
        }
    }
    .exhibitor-slide {
        &.exhibitor-slide--ABE {
            .exhibitor-slide__side-flag {
                background: #26358c;
            }
        }
        &.exhibitor-slide--FME {
            .exhibitor-slide__side-flag {
                background: #ed6839;
            }
        }
         &.exhibitor-slide--TEE {
            .exhibitor-slide__side-flag {
                background: #0f52ab;
            }
        }
    }
       h1.profile-title{
        color:#333333;
        
    }
}

