.control-col {
	.react__sticky-nav {
		@media (max-width: $mobile-width) {
			&.nav-affix {
				&--affix {
					top: 56px !important;
					width: 100% !important;
					background: #fff;
					left: 0;
					right: 0;
					z-index: 1;
					padding: 10px;
					box-shadow: 0 4px 2px -2px gray;
				}
			}
		}
		&.nav-affix {
			&--affix {
				position: fixed;
				top: 80px;
			}
		}
	}
	.curved-cta--max {
		a {
			font-size: 1rem;
		}
	}
	&__header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 5px;
		border-bottom: 2px solid black;
		h3 {
			margin: 0;
			line-height: inherit;
		}
		.control-col__reset {
			padding: 5px;
			margin: 0;
		}
	}
	.control-col__download-list,
	.control-col__reg-link,
	.control-col__show-filter {
		width: 100%;
		margin: 10px 0px;
	}
	.control-col__reg-link{
	    display:none;
	}
	.control-col__show-filter {
		span {
			display: flex;
			justify-content: space-between;
			align-items: center;
			span {
				color: #000000;
			}
			img {
				width: 20px;
			}
		}
			//ORDER fME-ABE-TEE
	$ids: ("a1xUK000000iHI5", #ed6839, #fddecd), ("a1xUK000000iHJh", #26358c, #cfddef),("a1xUK000001MzNd", #0f52ab, #B4D2F8);

		@each $id in $ids {
			&--#{nth($id, 1)} {
				$background: nth($id, 3);
				background-color: $background;
				border-left: 10px solid nth($id, 2);
				&:hover {
					background-color: lighten($background, 5%) !important;
				}
			}
		}

		&:hover {
			box-shadow: none;
		}
	}
}
